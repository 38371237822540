export const mainMenuItems = [
  {
    name: 'HOME',
    stateName: 'main.home',
  },
  {
    name: 'HOME',
    stateName: 'main.welcome',
  },
  {
    name: 'COURSE CATALOG',
    stateName: 'main.search',
    stateParams: { type: 'course' },
  },
  {
    name: 'YOUR LEARNING',
    stateName: 'main.learning',
  },
  {
    name: 'CALENDAR',
    stateName: 'main.calendar',
  },
  {
    name: 'RESOURCES',
    stateName: 'main.search',
    stateParams: { type: 'resource' },
  },
  {
    name: 'DISCUSSIONS',
    stateName: 'main.search',
    stateParams: { type: 'discussion' },
    data: { availableFor: ['regularUser', 'admin'] },
  },
  {
    name: 'ADMIN',
    href: '{{basePath}}admin',
    data: { availableFor: ['admin'] },
  },
  {
    name: 'HELP',
    stateName: 'main.helpDirectory',
  },
];
