<p *ngIf="item.content.description" class="line-clamp">
  <span [innerHTML]="item.content.description | markdownToHtml | htmlToPlainText"></span>
</p>

<div class="tw-flex tw-w-full tw-flex-nowrap tw-gap-2 tw-overflow-x-auto tw-pb-2 sm:tw-flex-wrap sm:tw-pb-0">
  <span
    class="twc-badge twc-badge-outline-secondary"
    *ngFor="let affiliate of item.content.availabilityAffiliates"
    title="This content is available to {{ affiliate }} users"
    ><span class="fa fa-users"></span> {{ affiliate }}
  </span>

  <span
    class="twc-badge twc-badge-outline-secondary"
    title="This course has a certificate"
    *ngIf="item.certificates?.length > 0">
    <span class="fa fa-certificate"></span> Certificate
  </span>

  <span class="twc-badge twc-badge-outline-secondary" *ngIf="item.courseCredits?.length === 1">
    <span class="fa fa-graduation-cap"></span> {{ item.courseCredits[0].name }}
  </span>
  <span class="twc-badge twc-badge-outline-secondary" *ngIf="item.courseCredits?.length > 1">
    <span class="fa fa-graduation-cap"></span> multiple credit types available
  </span>

  <span class="twc-badge twc-badge-outline-secondary" *ngIf="item.updatedDate">
    Last Updated on <time>{{ item.updatedDate | date: 'mediumDate' }}</time>
  </span>
  <course-publish-date [item]="item.content" *ngIf="item.publishDate"></course-publish-date>
  <course-expiration-date [course]="item.content" *ngIf="item.expirationDate"></course-expiration-date>

  <span *ngIf="item.content.continuingEducation">
    <course-ce-dates [course]="item.content"></course-ce-dates>
  </span>
</div>

<div class="label-list">
  <a
    *ngFor="let tag of item.content.tags"
    [uiSref]="'main.search'"
    [uiParams]="{ tag: tag.name }"
    class="label label-tag"
    [title]="tag.name">
    <span class="fa-solid fa-tag"></span>
    <span [innerText]="tag.name"></span>
  </a>
</div>
