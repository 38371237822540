import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';
import { ParamDeclaration } from '@uirouter/core';
import { searchTerms } from 'modules/search/common';
import { searchRoutingModule } from 'modules/search/search-routing.module';
import { SearchTrainComponent } from './components/search-train.component';

export const searchRoutingTrainModule: NgHybridStateDeclaration[] = [
  ...searchRoutingModule.filter((i) => i.name !== 'main.search'),

  {
    name: 'main.search',
    url: '^/search?' + searchTerms.join('&'),
    params: {
      ...searchTerms.reduce((obj: Record<string, ParamDeclaration>, key) => {
        obj[key] = {
          value: null,
          dynamic: true,
        };

        return obj;
      }, {}),
      query: {
        type: 'string',
        value: null, // <-- Default value
        dynamic: true,
        array: false,
      },
    },
    component: SearchTrainComponent,
    data: {
      label: 'Search',
      availableFor: ['anonymous', 'regularUser', 'admin'],
    },
  },
];
