import { Component, OnInit } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { CurrentUserService } from 'core/authorization';
import { GlobalConfig } from 'core/environment';
import { UrlParamService } from 'core/navigation';
import { SearchFiltersProviderService } from 'modules/search/common/services/search-filters-provider.service';
import { SearchComponent } from 'modules/search/components/search.component';
import { userSearchTrainFilterProviders } from '../services';

@Component({
  selector: 'search-train-component',
  templateUrl: './search-train.component.html',
  providers: [UrlParamService, ...userSearchTrainFilterProviders],
})
export class SearchTrainComponent extends SearchComponent implements OnInit {
  editAccountAvailable: boolean;
  mainPortal: boolean;

  override readonly itemsPerPage: number = 80;

  constructor(
    window: Window,
    urlService: UrlParamService,
    activeState: UIRouterGlobals,
    currentUser: CurrentUserService,
    private globalConfig: GlobalConfig,
    searchFiltersProvider: SearchFiltersProviderService,
  ) {
    super(window, urlService, activeState, currentUser, searchFiltersProvider);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.editAccountAvailable = !!this.urlService.getState('edit.account');
    this.mainPortal = this.globalConfig.base?.toLowerCase().includes('main');
  }
}
