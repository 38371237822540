<!--Help text-->
<div class="row">
  <div class="col-xs-12">
    <p class="tw-mb-4">
      Use this page to search for any course or document on the TRAIN Learning Network site. The results may be limited
      by any groups you have joined within TRAIN
      <span *ngIf="editAccountAvailable">
        (<a [uiSref]="'edit.account'" title="Edit Account">see your profile for details</a>).
      </span>
    </p>
  </div>
</div>

<div class="row sm-margin-bottom">
  <fieldset class="no-margin-bottom">
    <legend class="sr-only">Search</legend>

    <div class="col-sm-9 col-xs-12 sm-margin-bottom">
      <keyword-search-form
        placeholder="Search TRAIN"
        [(value)]="query"
        [pending]="processing"
        [disabled]="pending"
        (formSubmit)="onSubmit()">
        <manage-saved-searches *ngIf="!anonymous" class="input-group-btn search-controls"></manage-saved-searches>
      </keyword-search-form>
    </div>

    <div class="col-sm-3 col-xs-12 sm-margin-bottom" *ngIf="!mobileView">
      <select
        class="form-control"
        title="Sort results by"
        aria-label="Sort results by"
        [(ngModel)]="sorting"
        [disabled]="pending"
        (ngModelChange)="applySort($event)">
        <option *ngFor="let item of sortOptions" [ngValue]="item">{{ item.title }}</option>
      </select>
    </div>

    <div class="col-sm-3" *ngIf="!hasCustomFilters">
      <extended-object-filter [disabled]="pending" (filterChanged)="applyFilter($event)"></extended-object-filter>
    </div>

    <search-mobile-action-panel *ngIf="mobileView" class="col-xs-12" [filters]="filters"></search-mobile-action-panel>
  </fieldset>
</div>

<div class="row">
  <!--Left filtering panel-->
  <div class="col-sm-4 col-md-3 col-xs-12" *ngIf="hasCustomFilters && !mobileView">
    <h4 class="no-margin-top">Filters</h4>
    <search-filter-view *ngFor="let s of filters.ui" [filterService]="s" [isOpen]="s.filter.open"></search-filter-view>

    <div *ngIf="advancedOptions">
      <search-filter-view
        *ngFor="let s of filters.advanced"
        [filterService]="s"
        [isOpen]="s.filter.open"></search-filter-view>
    </div>

    <div *ngIf="advancedVisible.length > 0" class="panel panel-sm panel-default">
      <div class="panel-body text-center">
        <a href="#" (click)="advancedOptions = !advancedOptions; $event.preventDefault()">
          <span class="fa" [ngClass]="{ 'fa-eye': !advancedOptions, 'fa-eye-slash': advancedOptions }"></span
          ><span *ngIf="!advancedOptions" class="xs-padding-left">Show Advanced Options</span
          ><span *ngIf="advancedOptions" class="xs-padding-left">Hide Advanced Options</span>
        </a>
      </div>
    </div>

    <button
      type="button"
      class="btn btn-default standard-margin-bottom standard-margin-top btn-block"
      (click)="searchExport()">
      Export <span class="visible-lg-inline">Search Results</span>
    </button>
  </div>

  <!--Main panel-->
  <div class="col-md-9" [ngClass]="{ 'col-sm-8': !mobileView, 'col-sm-12': mobileView }">
    <div>
      <div class="well well-sm standard-margin-bottom">{{ itemsCount | number }} record(s) found.</div>
      <div class="label-list sm-margin-bottom search-filter-list">
        <selected-search-filters
          *ngFor="let f of filters.all"
          [filterService]="f"
          (filterChanged)="applyFilter($event)"></selected-search-filters>
      </div>

      <search-result-train
        [mode]="'expandedList'"
        [submit]="submitSubject"
        [itemsPerPage]="itemsPerPage"
        (itemCountChanged)="itemsCount = $event"
        (promise)="onFetchingChange($event)"></search-result-train>
    </div>
  </div>
</div>
