import { Component, Input } from '@angular/core';
import { ISearchTrainItem, ISearchTrainItemContent } from '../../models/search.models';

@Component({
  selector: 'course-content-tile',
  templateUrl: './course-content-tile.component.html',
})
export class CourseContentTileComponent {
  @Input() item: ISearchTrainItem<ISearchTrainItemContent>;
}
