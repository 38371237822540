import { Component, Input, OnInit } from '@angular/core';
import { ICourse } from 'modules/course/common/models/course.model';
import { SearchService } from 'modules/search/services';
import { Observable, map, of, tap } from 'rxjs';
import { ISearchTrainItem } from '../../search/models/search.models';

@Component({
  selector: 'cdc-quality-sign',
  templateUrl: './cdc-quality-sign.component.html',
})
export class CdcQualitySignComponent implements OnInit {
  @Input() course: ICourse;
  @Input() appearance: 'label' | 'badge' = 'label';

  meetQuality: boolean;

  constructor(private searchService: SearchService<ISearchTrainItem>) {}

  ngOnInit(): void {
    if (this.course) {
      this.checkCdcQuality(Number(this.course.id)).subscribe((result) => (this.meetQuality = result));
    } else {
      this.meetQuality = true;
    }
  }

  private checkCdcQuality(id: number): Observable<boolean> {
    const sessionStorageKey = `coursesQuality.${id}`;
    const qualityCheck = JSON.parse(sessionStorage.getItem(sessionStorageKey)) as { quality: boolean };

    if (!qualityCheck) {
      return this.searchService.search({ id, type: 'course', qualityCheck: true }).pipe(
        map((response) => response.items.length === 1 && response.items[0].quality === 'cdc'),
        tap((result) => sessionStorage.setItem(sessionStorageKey, JSON.stringify({ quality: result }))),
      );
    }

    return of(qualityCheck.quality);
  }
}
