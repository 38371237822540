import { CommonSearchFilterService } from 'modules/search/common/services/filters/common-filter.service';
import { CompetencyNodeFilterService } from 'modules/search/common/services/filters/competency-node-filter.service';
import { CourseFormatFilterService } from 'modules/search/common/services/filters/course-format-filter.service';
import { CourseFormatTypeFilterService } from 'modules/search/common/services/filters/course-format-type-filter.service';
import { CourseLabelFilterService } from 'modules/search/common/services/filters/course-label-filter.service';
import { CourseLanguageFilterService } from 'modules/search/common/services/filters/course-language-filter.service';
import { CourseRatingFilterService } from 'modules/search/common/services/filters/course-rating-filter.service';
import { CreditTypeFilterService } from 'modules/search/common/services/filters/credit-type-filter.service';
import { DistanceSearchFilterService } from 'modules/search/common/services/filters/distance-filter.service';
import { LsSearchFilterService } from 'modules/search/common/services/filters/ls-search-filter.service';
import { ObjectFilterService } from 'modules/search/common/services/filters/object-filter.service';
import { OrganizationFilterService } from 'modules/search/common/services/filters/organization-filter.service';
import { ScheduleByFilterService } from 'modules/search/common/services/filters/schedule-by-filter.service';
import { SingleGroupFilterService } from 'modules/search/common/services/filters/single-group-filter.service';
import { SubjectFilterService } from 'modules/search/common/services/filters/subject-filter.service';
import { TagSearchFilterService } from 'modules/search/common/services/filters/tag-search-filter.service';
import { TargetAudienceFilterService } from 'modules/search/common/services/filters/target-audience-filter.service';
import { TrainingCategoriesFilterService } from 'modules/search/common/services/filters/training-categories-filter.service';
import { UpdatedByFilterService } from 'modules/search/common/services/filters/updated-by-filter.service';
import { SearchFiltersMappingService } from 'modules/search/common/services/search-filter-mapping.service';
import { SearchFiltersProviderService } from 'modules/search/common/services/search-filters-provider.service';
import { CourseQualityFilterService } from './filtering/course-quality-filter.service';
import { SearchFiltersTrainMappingService } from './search-filter-mapping.service';

export const userSearchTrainFilterProviders = [
  // providers
  { provide: SearchFiltersMappingService, useClass: SearchFiltersTrainMappingService },
  SearchFiltersProviderService,

  // filters
  ObjectFilterService,
  TagSearchFilterService,
  CourseFormatFilterService,
  CourseLabelFilterService,
  CreditTypeFilterService,
  OrganizationFilterService,
  SubjectFilterService,
  TargetAudienceFilterService,
  LsSearchFilterService,
  CourseFormatTypeFilterService,
  TrainingCategoriesFilterService,
  SingleGroupFilterService,
  CourseQualityFilterService,
  CourseRatingFilterService,
  CommonSearchFilterService,
  CompetencyNodeFilterService,
  CourseLanguageFilterService,
  UpdatedByFilterService,
  ScheduleByFilterService,
  DistanceSearchFilterService,
];
