<a
  href="http://www.phf.org/resourcestools/Pages/CDC_Quality_Training_Standards.aspx"
  target="_blank"
  class="no-text-decoration hidden-xs"
  [ngbTooltip]="'Meets CDC Quality Training Standards. Click to learn more.'"
  placement="right"
  *ngIf="meetQuality"
  [ngClass]="{
    'label label-quality-search': appearance === 'label',
    'label-quality-thumbnail': appearance === 'badge',
  }">
  <span class="sr-only">Click to learn more about Meets CDC Quality Training Standards</span>
</a>
