import { Injectable } from '@angular/core';
import { UrlParamService } from 'core/navigation';
import { CustomCourseAttributeService } from 'modules/custom-attributes/services/custom-course-attribute.service';
import { BaseSearchFilterService } from 'modules/search/common/services/filters/base-search.service';
import { ISearchFilter } from 'modules/search/models/search-filters.models';

@Injectable()
export class CourseQualityFilterService extends BaseSearchFilterService {
  public override filter: ISearchFilter = {
    name: 'courseQualityFilter',
    label: 'Meets CDC Quality Training Standards',
    term: 'quality',
    mode: 'booleanFilter',
    multiChoice: false,
    open: false,
    items: [],

    appearance: 'panel',
    displayLimit: 10,
    visible: true,
  };

  constructor(
    urlService: UrlParamService,
    private attributeService: CustomCourseAttributeService,
  ) {
    super(urlService);
  }

  public load(): void {
    this.attributeService.getCategories([130]).subscribe((categories) => {
      this.filter.items = categories.map(({ id }) => ({ id, text: 'Quality Training', value: 'cdc' }));

      if (!this.filter.items.length) {
        this.filter.disabled = true;
      }

      this.initSelectedItems();
    });
  }
}
