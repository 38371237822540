import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { CompetencyService } from 'modules/competency/services/competency.service';
import { CourseCommonModule } from 'modules/course/common/course-common.module';
import { CustomAttributesModule } from 'modules/custom-attributes/custom-attributes.module';
import { SearchCommonModule } from 'modules/search/common/search-common.module';
import { SearchService } from 'modules/search/services';
import { SavedSearchesServive } from 'modules/search/services/saved-searches.service';
import { CdcQualitySignComponent } from '../course/components/cdc-quality-sign.component';
import { CourseContentTileComponent } from './components/search-items/course-content-tile.component';
import { SearchResultTrainComponent } from './components/search-result-train.component';
import { SearchTrainComponent } from './components/search-train.component';
import { searchRoutingTrainModule } from './search-routing-train.module';

@NgModule({
  imports: [
    UIRouterModule,
    CommonModule,
    FormsModule,
    NgbDropdownModule,
    NgbTooltipModule,
    CourseCommonModule,
    SearchCommonModule,
    LmsComponentsModule,
    CustomAttributesModule,
    UIRouterUpgradeModule.forChild({ states: searchRoutingTrainModule }),
  ],
  declarations: [SearchTrainComponent, SearchResultTrainComponent, CourseContentTileComponent, CdcQualitySignComponent],
  providers: [SearchService, SavedSearchesServive, CompetencyService],
})
export class SearchTrainModule {}
