import { Injectable, Type } from '@angular/core';
import { BaseSearchFilterService } from 'modules/search/common/services/filters/base-search.service';
import { CommonSearchFilterService } from 'modules/search/common/services/filters/common-filter.service';
import { CompetencyNodeFilterService } from 'modules/search/common/services/filters/competency-node-filter.service';
import { CourseFormatFilterService } from 'modules/search/common/services/filters/course-format-filter.service';
import { CourseFormatTypeFilterService } from 'modules/search/common/services/filters/course-format-type-filter.service';
import { CourseLabelFilterService } from 'modules/search/common/services/filters/course-label-filter.service';
import { CourseLanguageFilterService } from 'modules/search/common/services/filters/course-language-filter.service';
import { CourseRatingFilterService } from 'modules/search/common/services/filters/course-rating-filter.service';
import { CreditTypeFilterService } from 'modules/search/common/services/filters/credit-type-filter.service';
import { DistanceSearchFilterService } from 'modules/search/common/services/filters/distance-filter.service';
import { LsSearchFilterService } from 'modules/search/common/services/filters/ls-search-filter.service';
import { ObjectFilterService } from 'modules/search/common/services/filters/object-filter.service';
import { OrganizationFilterService } from 'modules/search/common/services/filters/organization-filter.service';
import { ScheduleByFilterService } from 'modules/search/common/services/filters/schedule-by-filter.service';
import { SingleGroupFilterService } from 'modules/search/common/services/filters/single-group-filter.service';
import { SubjectFilterService } from 'modules/search/common/services/filters/subject-filter.service';
import { TagSearchFilterService } from 'modules/search/common/services/filters/tag-search-filter.service';
import { TargetAudienceFilterService } from 'modules/search/common/services/filters/target-audience-filter.service';
import { TrainingCategoriesFilterService } from 'modules/search/common/services/filters/training-categories-filter.service';
import { UpdatedByFilterService } from 'modules/search/common/services/filters/updated-by-filter.service';
import { SearchFiltersMappingService } from 'modules/search/common/services/search-filter-mapping.service';
import { CourseQualityFilterService } from './filtering/course-quality-filter.service';

@Injectable()
export class SearchFiltersTrainMappingService extends SearchFiltersMappingService {
  protected override readonly mapping: Record<string, Type<BaseSearchFilterService>> = {
    objectFilter: ObjectFilterService,
    tagFilter: TagSearchFilterService,
    courseFormatFilter: CourseFormatFilterService,
    courseLabelFilter: CourseLabelFilterService,
    creditTypeFilter: CreditTypeFilterService,
    subjectFilter: SubjectFilterService,
    targetAudiencesFilter: TargetAudienceFilterService,
    lsFilter: LsSearchFilterService,
    courseFormatTypeFilter: CourseFormatTypeFilterService,
    organizationFilter: OrganizationFilterService,
    trainingCategoriesFilter: TrainingCategoriesFilterService,
    singleGroupFilter: SingleGroupFilterService,
    courseQualityFilter: CourseQualityFilterService,
    courseRatingFilter: CourseRatingFilterService,
    commonFilter: CommonSearchFilterService,
    competencyNodeFilter: CompetencyNodeFilterService,
    courseLanguageFilter: CourseLanguageFilterService,
    updatedByFilter: UpdatedByFilterService,
    scheduleByFilter: ScheduleByFilterService,
    distanceFilter: DistanceSearchFilterService,
  };
}
