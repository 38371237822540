<ul
  class="content-list"
  infiniteScroll
  [inViewport]="85"
  (infiniteScrollAction)="loadMore()"
  [ngClass]="{ 'disabled-text': processing }">
  <li class="content-list-item" *ngFor="let item of items" [ngClass]="{ 'is-open': item.expanded }">
    <div class="flex">
      <!--separate row for xs screens-->
      <div class="flex-row visible-xs">
        <div class="flex-col flex-col-grow" [ngSwitch]="item.type">
          <span *ngSwitchCase="'learning_series'">
            <a
              href="#"
              [uiSref]="'main.trainingPlan'"
              [uiParams]="{ id: item.id }"
              [innerHTML]="item.title"
              *ngIf="!processing"
              title="{{ item.title | htmlToPlainText }}"
              aria-label="Title"></a>
            <span class="disabled-link" *ngIf="processing" [innerHTML]="item.title"></span>
          </span>

          <span *ngSwitchDefault>
            <cdc-quality-sign *ngIf="item.quality?.includes('cdc')" appearance="label"></cdc-quality-sign>

            <span class="label label-price label-price-search" *ngIf="item.listPrice">
              <span class="fa fa-usd"></span>
            </span>

            <a
              href="#"
              [uiSref]="'main.' + item.type"
              [uiParams]="{ id: item.id }"
              [innerHTML]="item.title"
              *ngIf="!processing"
              title="{{ item.title | htmlToPlainText }}"
              aria-label="Title"></a>
            <span class="disabled-link" *ngIf="processing" [innerHTML]="item.title"></span>
          </span>
        </div>
      </div>

      <!-- visible on sm+ screens-->
      <div class="flex-row">
        <div
          class="flex-col hidden-xs"
          *ngIf="item.listPrice || item.quality?.includes('cdc')"
          [ngClass]="{
            'flex-col-3x d-flex gap-5': item.listPrice || item.quality?.includes('cdc'),
            'flex-col-6x': item.listPrice && item.quality?.includes('cdc'),
          }">
          <cdc-quality-sign *ngIf="item.quality?.includes('cdc')" appearance="label"></cdc-quality-sign>
          <span class="label label-price label-price-search" *ngIf="item.listPrice">
            <span class="fa fa-usd"></span>
          </span>
        </div>
        <div class="flex-col flex-col-grow">
          <div class="line-clamp hidden-xs" *ngIf="!processing" [ngSwitch]="item.type">
            <span *ngSwitchCase="'learning_series'">
              <a
                href="#"
                [uiSref]="'main.trainingPlan'"
                [uiParams]="{ id: item.id }"
                [innerHtml]="item.title"
                style="white-space: normal"
                title="{{ item.title | markdownToHtml | htmlToPlainText }}"
                aria-label="Title"></a>
            </span>
            <span *ngSwitchDefault>
              <a
                href="#"
                [uiSref]="'main.' + item.type"
                [uiParams]="{ id: item.id }"
                [innerHTML]="item.title"
                style="white-space: normal"
                title="{{ item.title | markdownToHtml | htmlToPlainText }}"
                aria-label="Title"></a>
            </span>
          </div>
          <div class="line-clamp hidden-xs" *ngIf="processing">
            <span class="disabled-link" [innerHTML]="item.title"></span>
          </div>
        </div>
        <div class="flex-col flex-col hidden-xs hidden-sm">
          <!--<span class="twc-badge twc-badge-outline-secondary " title="Created/updated date">Apr 2016</span>-->
        </div>
        <div class="flex-col" [ngSwitch]="item.type">
          <span *ngSwitchCase="'document'" class="label {{ 'label-' + item.type }}">Resource</span>
          <span *ngSwitchCase="'discussion'" class="label {{ 'label-' + item.type }}">Discussion</span>
          <span *ngSwitchCase="'blog'" class="label {{ 'label-' + item.type }}">Announcement</span>
          <span *ngSwitchCase="'learning_series'" class="label {{ 'label-' + item.type }}">Training Plan</span>
          <span *ngSwitchCase="'provider_organization'" class="label {{ 'label-' + item.type }}">Org</span>
          <span *ngSwitchCase="'event'" class="label label-event">Calendar Event</span>
          <span *ngSwitchDefault class="label {{ 'label-' + item.type }}">
            {{ item.labelName || item.formatName || item.type }}
          </span>
        </div>
        <div class="flex-col">
          <a
            href="#"
            (click)="toggleContent(item)"
            title="Expand"
            aria-label="Expand/Collapse"
            class="content-list-item-expand">
            <i class="fa" [ngClass]="{ 'fa-caret-up': item.expanded, 'fa-caret-down': !item.expanded }"></i>
          </a>
        </div>
      </div>

      <div
        class="flex-row hidden-xs hidden-sm"
        *ngIf="(item.type === 'course' && item.ratingCount) || item.cpOrganizationName">
        <div class="flex-col flex-col-15x">
          <div
            class="text-truncate"
            *ngIf="item.cpOrganizationName"
            placement="top-left"
            [ngbTooltip]="item.cpOrganizationName">
            <span class="text-truncate text-muted">{{ item.cpOrganizationShortName || item.cpOrganizationName }}</span>
          </div>
        </div>
        <div class="flex-col flex-col-grow">&nbsp;</div>
        <div class="flex-col flex-col-14x text-right" *ngIf="item.type === 'course' && item.ratingCount">
          <course-star-rating [course]="item" class="inline-block"></course-star-rating>
        </div>
      </div>
    </div>

    <div class="content-list-item-details text-muted clearfix xs-margin-top">
      <div *ngIf="!item.content" class="text-center">
        <div class="load-spinner"></div>
      </div>

      <div *ngIf="item.content">
        <div [ngSwitch]="item.type">
          <div *ngSwitchCase="'course'">
            <course-content-tile [item]="item"></course-content-tile>
          </div>
          <div *ngSwitchCase="'user'">
            <p *ngIf="item.content.loginName">{{ item.content.loginName }}</p>
          </div>
          <div *ngSwitchCase="'blog'">
            <p *ngIf="item.content.content" class="line-clamp">
              <span [innerHTML]="item.content.content | markdownToHtml | htmlToPlainText"></span>
            </p>
          </div>
          <div *ngSwitchCase="'discussion'">
            <p *ngIf="item.content.description" class="line-clamp">
              <span [innerHTML]="item.content.description | markdownToHtml | htmlToPlainText"></span>
            </p>

            <div class="label-list">
              <span class="twc-badge twc-badge-outline-secondary">
                Created by
                <em>
                  <span [innerText]="item.content.createdBy?.firstName">?</span>
                  <span [innerText]="item.content.createdBy?.lastName"></span>
                </em>
                on
                <em
                  ><time>{{ item.content.createdDate | date: 'mediumDate' }}</time></em
                >
              </span>
            </div>
          </div>
          <div *ngSwitchCase="'document'">
            <p *ngIf="item.content.description" class="line-clamp">
              <span [innerHTML]="item.content.description | markdownToHtml | htmlToPlainText"></span>
            </p>

            <div class="label-list">
              <span class="twc-badge twc-badge-outline-secondary">
                Created by
                <em>
                  <span [innerText]="item.content.createdBy?.firstName">?</span>
                  <span [innerText]="item.content.createdBy?.lastName"></span>
                </em>
                on
                <em
                  ><time>{{ item.content.createdDate | date: 'mediumDate' }}</time></em
                >
              </span>
            </div>
          </div>
          <div *ngSwitchCase="'resource'">
            <p *ngIf="item.content.description" class="line-clamp">
              <span [innerHTML]="item.content.description | markdownToHtml | htmlToPlainText"></span>
            </p>

            <div class="label-list">
              <span class="twc-badge twc-badge-outline-secondary label-wrap">
                Updated by
                <em>
                  <span [innerText]="item.content.updatedBy?.firstName">?</span>
                  <span [innerText]="item.content.updatedBy?.lastName"></span>
                </em>
                on
                <em
                  ><time>{{ item.content.updated | date: 'mediumDate' }}</time></em
                >
              </span>
            </div>
          </div>
          <div *ngSwitchCase="'announcement'">
            <p *ngIf="item.content.description" class="line-clamp">
              <span [innerHTML]="item.content.description | markdownToHtml | htmlToPlainText"></span>
            </p>
          </div>
          <div *ngSwitchCase="'learning_series'">
            <p *ngIf="item.content.description" class="line-clamp">
              <span [innerHTML]="item.content.description | markdownToHtml | htmlToPlainText"></span>
            </p>

            <div class="label-list">
              <span
                class="twc-badge twc-badge-outline-secondary"
                *ngFor="let affiliate of item.content.availabilityAffiliates"
                title="This content is available to {{ affiliate }} users"
                ><span class="fa fa-users"></span>
                {{ affiliate }}
              </span>
            </div>
          </div>
          <div *ngSwitchCase="'provider_organization'" class="xs-margin-top">
            <span *ngIf="item.content.url">
              <a [href]="item.content.url" target="_blank">
                <span class="fa fa-external-link inline-block sm-margin-right" aria-hidden="true"></span>
                {{ item.content.url }}
              </a>
            </span>
            <span *ngIf="item.content.phone">
              <a [href]="'tel:' + item.content.phone">
                <span class="fa fa-phone fa-fw inline-block sm-margin-right" aria-hidden="true"></span>
                {{ item.content.phone }}
              </a>
            </span>
            <span *ngIf="item.content.email">
              <a [href]="'mailto:' + item.content.email">
                <span class="fa-regular fa-envelope fa-fw inline-block sm-margin-right" aria-hidden="true"></span>
                {{ item.content.email }}
              </a>
            </span>
          </div>
          <div *ngSwitchCase="'event'">
            <p *ngIf="item.content.description" class="line-clamp">
              <span [innerHTML]="item.content.description | markdownToHtml | htmlToPlainText"></span>
            </p>

            <div class="flex">
              <div class="flex-row">
                <div class="flex-col flex-col-grow" *ngIf="item.content.startDate">
                  <date-time [date]="item.content.startDate"></date-time>
                  <span *ngIf="item.content.endDate"> - <date-time [date]="item.content.endDate"></date-time></span>
                </div>
              </div>
            </div>
          </div>
          <div *ngSwitchDefault>
            <p *ngIf="item.content.description" class="line-clamp">
              <span [innerHTML]="item.content.description | markdownToHtml | htmlToPlainText"></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </li>
</ul>
