import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccreditationAgenciesModule } from 'modules/accreditation-agencies/accreditation-agencies.module';
import { AlertsModule } from 'modules/alerts/alerts.module';
import { BlogModule } from 'modules/blog/blog.module';
import { CalendarModule } from 'modules/calendar/calendar.module';
import { CertificatesModule } from 'modules/certificates/certificates.module';
import { CompetencyModule } from 'modules/competency/competency.module';
import { CourseModule } from 'modules/course/course.module';
import { CreditsModule } from 'modules/credits/credits.module';
import { DiscussionsModule } from 'modules/discussions/discussions.module';
import { EcommerceCheckoutModule } from 'modules/ecommerce/checkout/ecommerce-checkout.module';
import { EcommercePayLaterModule } from 'modules/ecommerce/pay-later/ecommerce-pay-later.module';
import { EmailsModule } from 'modules/emails/emails.module';
import { EventsModule } from 'modules/events/events.module';
import { ExternalApplicationsModule } from 'modules/external-applications/external-applications.module';
import { GroupModule } from 'modules/group/group.module';
import { HelpTextModule } from 'modules/help-texts/help-text.module';
import { InstructorsModule } from 'modules/instructors/instructors.module';
import { IntegrationsModule } from 'modules/integrations/integrations.module';
import { LoginModule } from 'modules/login/login.module';
import { NavigationModule } from 'modules/navigation/navigation.module';
import { NetworkModule } from 'modules/network/network.module';
import { PasswordModule } from 'modules/password/password.module';
import { QuizModule } from 'modules/quiz/quiz.module';
import { ResourcesModule } from 'modules/resources/resources.module';
import { SettingsModule } from 'modules/settings/settings.module';
import { UserAchievementsModule } from 'modules/user-achievements/user-achievements.module';
import { UserLearningModule } from 'modules/user-learning/user-learning.module';
import { UserExternalApplicationsModule } from 'modules/user/external-applications/user-external-applications.module';
import { UserNetworkModule } from 'modules/user/network/user-network.module';
import { UserModule } from 'modules/user/user.module';
import { SearchTrainModule } from './search/search-train.module';

@NgModule({
  imports: [
    NavigationModule,
    UserModule,
    CommonModule,
    ResourcesModule,
    AlertsModule,
    HelpTextModule,
    InstructorsModule,
    IntegrationsModule,
    BlogModule,
    GroupModule,
    NetworkModule,
    DiscussionsModule,
    CourseModule,
    EventsModule,
    CertificatesModule,
    CreditsModule,
    QuizModule,
    EmailsModule,
    SettingsModule,
    SearchTrainModule,
    CalendarModule,
    CompetencyModule,
    EcommerceCheckoutModule,
    EcommercePayLaterModule,
    AccreditationAgenciesModule,
    UserAchievementsModule,
    UserLearningModule,
    UserNetworkModule,
    LoginModule,
    PasswordModule,
    ExternalApplicationsModule,
    UserExternalApplicationsModule,
  ],
})
export class LmsTrainModule {}
