import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { UrlParamService } from 'core/navigation';
import { EntityDataService, ErrorHandlerService } from 'core/services';
import { SearchResultComponent } from 'modules/search/common/components/search-result.component';
import { SearchService } from 'modules/search/services';
import { EMPTY, catchError, finalize } from 'rxjs';
import { ISearchTrainItem, ISearchTrainItemContent } from '../models/search.models';

@Component({
  selector: 'search-result-train',
  templateUrl: './search-result-train.component.html',
})
export class SearchResultTrainComponent extends SearchResultComponent {
  public override items: ISearchTrainItem<ISearchTrainItemContent>[] = [];

  constructor(
    urlService: UrlParamService,
    searchService: SearchService,
    protected dataService: EntityDataService,
    private errorHandlerService: ErrorHandlerService,
  ) {
    super(urlService, searchService);
  }

  toggleContent(item: ISearchTrainItem<ISearchTrainItemContent>) {
    item.expanded = !item.expanded;

    if (item.expanded && !item.content) {
      this.dataService
        .get<ISearchTrainItemContent>(item.type, item.id)
        .pipe(catchError((e: HttpErrorResponse) => this.handleError(e, item)))
        .subscribe((data) => (item.content = data));
    }
  }

  loadMore() {
    if (this.itemCount > this.items.length && !this.requestSubscriber) {
      this.promise.emit(true);
      this.requestSubscriber = this.searchService
        .searchNext()
        .pipe(
          finalize(() => {
            this.promise.emit(false);
            this.requestSubscriber?.unsubscribe();
            this.requestSubscriber = null;
          }),
        )
        .subscribe((response) => {
          this.items = this.items.concat(response.items as ISearchTrainItem<ISearchTrainItemContent>[]);
        });
    }
  }

  private handleError(e: HttpErrorResponse, item: ISearchTrainItem<ISearchTrainItemContent>) {
    this.errorHandlerService.handleForbiddenError(e);
    item.expanded = false;

    return EMPTY;
  }
}
